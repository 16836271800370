@use "src/styles/palette";
@use "src/styles/typography";

.card {
  border-radius: .25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.vertical {
    flex-direction: row;
  }
}

.image {
  width: 100%;
  height: 100%;
  .vertical & {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
  }
}

.card_content {
  padding: 0.625rem 1.25rem 1.875rem 1.25rem;
  .vertical & {
    flex: 1 1 100%;
  }
}

.name {
  @extend .header_5;

  margin-bottom: .625rem;
}

.description {
  line-height: 1.5;
  margin-bottom: 1rem;
}

.contact {
  display: flex;
  column-gap: .5rem;
  align-items: center;
  margin-bottom: .4rem;
  color: palette.$matte-black;
  text-decoration: none;

  svg {
    width: 1rem;
    margin-right: .5rem;
  }
}

.socials_title {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: .3125rem;
}

.socials {
  display: flex;
  column-gap: 1.25rem;
}

.social {
  text-decoration: none;
}