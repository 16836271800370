.image {
  position: relative;
}

.top_rightcomponent,
.bottom_leftcomponent,
.top_leftcomponent {
  position: absolute;
  z-index: 1;
}

.top_leftcomponent {
  top: 0;
  left: 0;
}

.top_rightcomponent {
  top: 0;
  right: 0;
}

.bottom_leftcomponent {
  bottom: 0;
  left: 0;
}
